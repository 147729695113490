.app_nav__link, .app_nav__link:hover {
	margin-bottom: auto;
	color: #fff;
	display: inline-block;
	height: 26px;
	font-size: 14px;
	font-weight: 400;
	padding: 13px 15px 31px;
  border-radius: 4px 4px 0px 0px;
}

.app_nav__link_active, .app_nav__link_active:hover {
	background-color: white;
	color: #0379ab;
	font-weight: 600;
}
