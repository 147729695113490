body {
  margin: 0;
	padding: 0;
	font-size: 14px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

body #root {
	height: 95vh;
}
