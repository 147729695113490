.deleteAlert {
  padding: 10px;
  font-weight: 500;
}

.deleteAlertButtons {
  display: flex;
  justify-content: flex-end;
}

.deleteAlertButtons button {
  margin-left: 5px;
}
