.parentContainer {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 0 0 #137cbd00, 0 0 0 0 rgba(19,124,189,0), inset 0 0 0 1px rgba(16,22,26,.15), inset 0 1px 1px rgba(16,22,26,.2);
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
}

.container {
  padding-left: 2px;
  padding-right: 2px;
  overflow: auto;
  max-height: 300px;
}

.dragHandle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  cursor: move;
}

.sortableHelper {
  z-index: 10000;
}