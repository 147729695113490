.helpLink:visited {
  color: #048abb !important;
}
.helpLink:hover {
  color: #048abb !important;
}
.helpLink:active{
  color: #048abb !important;
}
.helpLink {
  color: #048abb !important;
}