.backupsContainer {
  display: flex;
  height: 100%;
}

.backupsContainer > div {
  margin: 20px;
}

.listContainer {
  width: 400px;
  overflow-y: scroll;
}

.detailsContainer {
  width: 100%;
}
