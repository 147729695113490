.progressBarContainer {
  background-color: #f8f8f8;
  overflow: hidden;
}

.progressBarContainerBorder {
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
}

.rounded {
  border-radius: 6px;
}

.progressBar {
  display: block;
  padding: 1px;
  transition: width 1s ease-in-out,
}
