.tooltip {
  max-width: 300px;
}

.confirmAlert {
  padding: 10px;
  font-weight: 500;
}

.confirmAlertButtons {
  display: flex;
  justify-content: flex-end;
}

.confirmAlertButtons button {
  margin-left: 5px;
}

.buttonNoWrap {
  white-space: nowrap;
}