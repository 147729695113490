.infoPopoverContainer {
  max-width: 400px;
}

.contentContainer {
  display: flex;
  padding: 10px;
}

.popoverButton {
  cursor: pointer;
}

.infoIcon {
  margin-right: 10px;
}

.content {
  margin: 0;
  flex-direction: column;
}
