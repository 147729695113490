.tag {
  display: flex;
  align-items: center;
  margin: 2px;
  padding: 3px 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: 400;
  cursor: default;
  user-select: none;
  white-space: nowrap;
}

.selectableTag {
  cursor: pointer;
}

.removeButton {
  margin-left: 5px;
  cursor: pointer;
}
