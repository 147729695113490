.organisationItem {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}

.organisationItem:hover {
  background-color: rgba(0, 123, 255, 0.2);
  border: 1px solid #137cbd;
}

.selectedOrganisation {
  background-color: rgba(0, 123, 255, 0.2);
}

.organisationItemHeader {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}
