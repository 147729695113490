.noSelection {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 30px;
}

.backupCard {
  margin-bottom: 20px;
  display: flex;
}

.backupDetails {
  flex-grow: 1;
}

.backupDetails > p {
  font-size: 1rem;
}

.backupDetails > p:last-child {
  margin-bottom: 0px;
}

.detailContent {
  font-weight: 600;
}

.backupButtons {
  display: flex;
  flex-direction: column;
  flex-basis: 150px;
}

.backupButtons > button {
  margin-bottom: 10px;
}

.backupButtons > button:last-child {
  margin-bottom: 0px;
}
