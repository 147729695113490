.headerMessage {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: bold;
  color: rgb(33, 49, 60);
  letter-spacing: 0.03em;
}


.formLabel {
  margin-bottom: 0px;
  font-size: 14px;
  color: rgb(61, 79, 88);
  position: relative;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.fullButton {
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
}

.rightButton {
  display:flex;
  justify-content: flex-end;
}

.forgottenPasswordHeader {
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
  color: rgb(33, 49, 60);
  letter-spacing: 0.03em;
}

.secondaryMessage {
  margin-bottom: 20px;
}

.loggingInMessage {
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: rgb(33, 49, 60);
  letter-spacing: 0.03em;
}

.loginSpinner {
  margin-top: 30px;
}

.errorMessage {
  margin-bottom: 20px;
  overflow-wrap: break-word;
}

.passwordIssue {
  color: red;
  font-weight: 600;
  margin-top: 5px;
}
