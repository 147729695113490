.container {
  display: flex;
  flex-direction: row;
}

.number {
  flex-grow: 1;
}

.select {
  flex-grow: 2;
}