.tagContainer {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.wrappedContainer {
  flex-wrap: wrap;
}

.hiddenCount {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  user-select: none;
}
